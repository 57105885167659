<template>
  <div>
    <el-upload
      action="#"
      accept=".xls,.xlsx"
      :show-file-list="false"
      :http-request="onChange"
      :before-upload="beforeAvatarUpload">
      <el-button type="primary">{{object.Object && object.Object.label ? object.Object.label : name}}</el-button>
    </el-upload>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'

export default {
  props: {
    object: {
      type: Object,
      default () {
        return {}
      }
    },
    name: {
      type: String,
      default () {
        return ''
      }
    },
    api: {
      type: Function
    }
  },
  methods: {
    beforeAvatarUpload (file) {
    },
    onChange (params) {
      if (this.object.Object && this.object.Object.isDirect) {
        this.$emit('isComplete', params)
        return
      }
      const formData = new FormData()
      formData.append('file', params.file)
      this.$confirm(this.$t('message.whetherToContinue', { name: params.file.name }), this.$t('text.tips'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.state.app.loading = this.$loading(loading)
        if (this.object.Object && this.object.Object.api) {
          this.object.Object.api(formData).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.importSuccess')
            })
            this.$emit('isComplete', params)
          }).finally(() => {
            this.$store.state.app.loading.close()
          })
        } else if (this.api) {
          this.api(formData).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.importSuccess')
            })
            this.$emit('sucImport')
          }).finally(() => {
            this.$store.state.app.loading.close()
          })
        }
      }).catch(() => {
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
