/**
 * 团长相关接口
 */
import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/hosts/setup'
  })
}

export const getHosts = (params) => {
  return request({
    method: 'GET',
    url: '/cms/hosts',
    params
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'get',
    url: `/cms/hosts/${id}/preview`
  })
}

export const getHostsId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hosts/${id}`
  })
}

export const getBankNameList = () => {
  return request({
    method: 'GET',
    url: '/cms/hosts/bank_name_list'
  })
}

export const getHostTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/hosts/host_type_list'
  })
}

export const putHostsId = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/hosts/${id}`,
    data
  })
}

export const getHostList = () => {
  return request({
    method: 'GET',
    url: '/cms/hosts/host_list'
  })
}

export const postImport = (data) => {
  return request({
    method: 'post',
    url: '/cms/hosts/import',
    data
  })
}

export const getExport = (params) => {
  return request({
    method: 'get',
    url: '/cms/hosts/export',
    params
  })
}
