import * as hostsApi from '@/services/hosts'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
import importButton from '@/components/importButton'

export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect,
    importButton
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0,
      hostsApi
    }
  },
  created () {
  },
  mounted () {
    const _this = this
    _this.queryData(hostsApi.getSetup)
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    // 点击操作按钮
    async handleClick (type, data) {
      const _this = this
      if (type?.action === 'view') {
        _this.$router.push({
          name: 'buddyManagerBuddyDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        _this.$router.push({
          name: 'buddyManagerBuddyEdit',
          params: {
            id: data.id.value
          }
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      hostsApi.getHosts(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 导出数据接口
    handleExport () {
      this.$refs.searchForm.commonOperate(hostsApi.getExport, (data) => {
        this.$router.push({
          name: 'downloadCenter',
          params: { data }
        })
      }, this)
    }
  }
}
